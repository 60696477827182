<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
        <BHLoading :show="loadingPage" />
        <div class="leadButtons jSB aC">
            <div class="dF aC">
                <h4 class="mb-3 mt-3">{{sidebarSelection}}</h4>
                <div v-if="(selectedRowKeys.length && selectedRowKeys.length > 1) || selectAllLeads" class="dF aC">
                    <div class="ml-3" style="color:var(--orange)">{{selectAllLeads ? totalContacts + ' selected' : `${selectedRowKeys.length} selected`}}</div>
					<!-- <a-button @click="selectAll()" :type="selectAllLeads ? 'primary' : ''" class="py-2 px-3 ml-3 text-center dF aC jC" style="width:130px; height:38px;">Select All Leads</a-button> -->
                    <div @click="bulkEmail" v-if="false" class="py-2 px-2 ml-5 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:100px">Bulk Email</div>
                    <div v-if="!selectAllLeads" @click="openModal('assignTo')" class="py-2 px-2 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:100px">Assign To</div>
                    <!-- <div @click="openModal('merge')" class="py-2 px-3 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:100px">Merge</div> -->
                    <div v-if="!selectAllLeads" @click="bulkDelete" class="py-2 px-2 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:100px">Delete</div>
					<div @click="openModal('bulkApplyTags')" class="py-2 px-2 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:130px">Apply Tags</div>
					<div @click="openModal('bulkDeleteTags')" class="py-2 px-2 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:130px">Delete Tags</div>
					<div @click="openModal('bulkLeadSource')" class="py-2 px-2 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer; width:150px">Update Lead Source</div>
                </div>
            </div>
            <div class="dF aC">
				<div @click="downloadFile" class="dF aC px-3 py-2 mr-3" style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
					<a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Unsubscribes will not be included on exported file.
                        </template>
						<span>Export Filtered Leads</span>
						<a-icon class="ml-2" type="download" />
                    </a-tooltip>
                </div>
                <div @click="showColumns" class="dF aC px-3 py-2" style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
                    <div class="mr-2">Customized Columns</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-columns"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg>
                </div>
                <div @click="showFilter" class="dF aC px-3 py-2 ml-3" style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
                    <div class="mr-2">Filters</div>
                    <i class="fe fe-filter" />
                </div>
            </div>
        </div>
        <EditDrawer @update="update" />
        <a-modal :visible="modal.visible" :centered="true" :footer="null" @cancel="closeModal">
            <div v-if="modal.type && modal.type == 'assignTo'">
                <h5>Assign To</h5>
                <div>Assign selected leads to this owner:</div>
                <a-form-model ref="assignTo" :model="assignTo">
                    <a-form-model-item prop="owner" :rules="req('Please select an owner')">
                        <a-select class="mt-3" style="width:100%" v-model="assignTo.owner" mode="multiple" placeholder="Add owner" size="large" :filter-option="filterOption" @search="searchUser">
                            <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                {{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-if="modal.type && modal.type == 'merge'">
                <h5>Merge Contacts</h5>
                <div>Select a primary record. Data from the secondary records are merged to the primary record. All secondary records are deleted.</div>
                <a-form-model class="mt-2" ref="mergeContact" :model="mergeContact">
                    <a-form-model-item prop="primary" :rules="req('Please select one')">
                        <a-radio-group v-model="mergeContact.primary">
                            <a-radio :class="keyI != 0 ? 'mt-2':''" style="display:block" v-for="(key, keyI) in selectedRowKeys" :key="key" :value="key">{{storeContacts[key] && storeContacts[key].firstName && storeContacts[key].lastName ? `${storeContacts[key].firstName} ${storeContacts[key].lastName}`:''}}</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-form-model>
            </div>
			<div v-if="modal.type && (modal.type == 'bulkApplyTags' || modal.type == 'bulkDeleteTags')">
                <h5>{{modal.type == 'bulkApplyTags' ? 'Bulk Apply Tags' : 'Bulk Delete Tags'}}</h5>
                <div v-if="modal.type == 'bulkApplyTags'">Select tags you would like to add to the selected leads</div>
				<div v-else>Select the tags you would like to remove from the selected leads</div>

				<a-form-model v-if="modal.type == 'bulkApplyTags'" class="mt-2" ref="bulkApplyTags" :model="bulkApplyTags">
                    <a-form-model-item prop="tags" :rules="req('Please select one')">
                        <a-select v-model="bulkApplyTags.tags" mode="tags" style="width: 100%" placeholder="Add tags" size="large">
							<a-select-option v-for="(tag,tagI) in tags" :value="tag.name" :key="tag.name + tagI">
								{{tag.name}}
							</a-select-option>
						</a-select>
                    </a-form-model-item>
                </a-form-model>
				<a-form-model v-else class="mt-2" ref="bulkDeleteTags" :model="bulkDeleteTags">
                    <a-form-model-item prop="tags" :rules="req('Please select one')">
                        <a-select v-model="bulkDeleteTags.tags" mode="multiple" style="width: 100%" placeholder="Add tags" size="large">
							<a-select-option v-for="(tag,tagI) in tags" :value="tag.name" :key="tag.name + tagI">
								{{tag.name}}
							</a-select-option>
						</a-select>
                    </a-form-model-item>
                </a-form-model>
            </div>
			<div v-if="modal.type && modal.type == 'bulkLeadSource'">
                <h5>Bulk Update Lead Source</h5>
				<div>Select the lead source you would like to apply to the selected leads</div>

				<a-form-model class="mt-2" ref="bulkLeadSource" :model="bulkLeadSource">
                    <a-form-model-item prop="source" :rules="req('Please select one')">
                        <a-select v-model="bulkLeadSource.source" placeholder="Select one" size="large">
							<a-select-option v-for="source in leadSources" :key="source.id" :value="source.id">{{source.name}}</a-select-option>
						</a-select>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="dF jE mt-3">
                <a-button @click="closeModal" class="cancel-button mr-3" style="width:125px;" size="large">CANCEL</a-button>
                <a-button @click="submit" type="primary" style="width:125px" size="large" :loading="modalLoading">SAVE</a-button>
            </div>
        </a-modal>
        <contactDetails :time="refresh" @deleteContact="deleteContact" />
        <ColumnsDrawer @updateColumns="updateColumns" :columns="columns" />
        <FilterDrawer />
        <!-- Main Contacts Page -->

        <template v-if="contacts.length && !showExpandDetails">

            <a-card :bodyStyle="{padding:0}">
                <div>
                    <a-table :rowKey="(e) => e.id" @change="handleChange" :columns="columns" :pagination="false" :row-selection="rowSelection" :dataSource="contacts" class="white-table hide-scrollbar" :scroll="{ x: 1350 }">
                        <div slot="name" slot-scope="obj" class="dF" style="align-items: center;" @click="contactDetails(obj)">
                            <div class="mr-3">
                                <a-avatar style="background-color:#1070CA" shape="circle" size="large" :class="$style.avatar">{{obj.firstName && obj.lastName && obj.firstName != '' && obj.lastName != '' ? obj.firstName[0].toUpperCase() + obj.lastName[0].toUpperCase() : '--'}}</a-avatar>
                            </div>
                            <div>{{obj.firstName && obj.lastName && obj.firstName != '' && obj.lastName != '' ? obj.firstName + ' ' + obj.lastName : '--'}}</div>
                        </div>
                        <div slot="email" slot-scope="obj" @click="contactDetails(obj)" style="color:var(--orange)">
                            {{obj.email}}
                        </div>
                        <div slot="tags" slot-scope="obj" @click="contactDetails(obj)">
							<div v-if="obj.tags.length">
								<a-tooltip
									:title="allTags(obj.tags)"
									class="dF aC"
									overlayClassName="change-tooltip-color"
								>
									<div class="px-2" style="
											background-color: #f7f5f9;
											border-radius: 5px;
											border: 1px solid #d0c9d6;
											min-width: 50px;
											display: block;
											word-wrap:break-word;
											white-space: normal;">
										{{ tags[obj.tags[0]] && tags[obj.tags[0]].name }}
									</div>
									<div
										v-if="obj.tags.length > 1"
										class="px-2 ml-3"
										style="
											background-color: #f7f5f9;
											border-radius: 5px;
											border: 1px solid #d0c9d6;
										"
									>
										{{`+${obj.tags.length-1}`}}
									</div>
								</a-tooltip>
							</div>
                        </div>
                        <div slot="company" slot-scope="obj" @click="contactDetails(obj)">
                            {{obj.company}}
                        </div>
                        <div slot="owners" class="text-center" slot-scope="obj" @click="contactDetails(obj)">
                            <template v-if="obj.owners && obj.owners.length">
                                <div v-for="id in unique(obj.owners)" :key="id" :value="id">{{storeUsers[id] && storeUsers[id].firstName && storeUsers[id].lastName && storeUsers[id].firstName != '' && storeUsers[id].lastName !='' ? `${storeUsers[id].firstName} ${storeUsers[id].lastName}` : ''}}</div>
                            </template>
                        </div>
                        <div slot="status" slot-scope="obj" @click="contactDetails(obj)">
                            {{obj.leadStatus && obj.leadStatus != null && typeof obj.leadStatus == 'object' ? obj.leadStatus.name : obj.leadStatus && obj.leadStatus != null && typeof obj.leadStatus == 'string' && statuses[obj.leadStatus] && statuses[obj.leadStatus].name ? statuses[obj.leadStatus].name : ''}}
                        </div>
						<span slot="interactionActivity">
							<a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    This helps sales determine the amount of interactions the lead has had in regards to form submission, email interaction.
                                </template>
								Interaction Activity
                                <a-icon type="question-circle" style="font-size: 14px" />
                            </a-tooltip>
						</span>
                        <div slot="leadScore" slot-scope="obj" @click="contactDetails(obj)">
							<a-progress status="normal" :percent="+((obj.leadScore/$store.state.contacts.maxLeadScore) * 100).toFixed(2)" size="small" strokeColor="#f7941e">
								<template #format="percent">
									<span style="font-size: medium;">
										{{ obj.leadScore }}
									</span>
								</template>
							</a-progress>
                        </div>
						<div class="text-center" slot="source" slot-scope="obj" @click="contactDetails(obj)">
                            {{getLeadSourceName(obj.source)}}
                        </div>
                        <div slot="lastUpdateDate" slot-scope="obj" style="color: #9EA0A5;" @click="contactDetails(obj)">
                            {{convertDate(obj.updatedAt)}}
                        </div>
                        <div slot="inactiveDays" slot-scope="obj" @click="contactDetails(obj)">
                            {{getInactive(obj.updatedAt) || 0}}
                        </div>
                        <div slot="jobTitle" slot-scope="obj">
                            {{obj.jobTitle}}
                        </div>
                        <div slot="phone" slot-scope="obj">
                            {{displayPhone(obj.phone)}}
                        </div>
                        <div slot="address" slot-scope="obj">
                            {{obj.address}}
                        </div>
                        <div slot="city" slot-scope="obj">
                            {{obj.city}}
                        </div>
                        <div slot="region" slot-scope="obj">
                            {{obj.region}}
                        </div>
                        <div slot="country" slot-scope="obj">
                            {{obj.country == "Select a country" ? '':obj.country}}
                        </div>
                        <div slot="postal" slot-scope="obj">
                            {{obj.postal}}
                        </div>
                        <div slot="createdAt" slot-scope="obj" style="color: #9EA0A5;">
                            {{convertDate(obj.createdAt)}}
                        </div>
						<div slot="unsub" slot-scope="obj" class="text-center">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title" v-if="obj.bounced">
									Click to view bounce reason
								</template>
								<template slot="title" v-if="obj.unsub && obj.unsubReason">
									{{ obj.unsubReason }}
								</template>
								<a-tag v-if="obj.bounced" @click="viewBounceReason(obj)" style="cursor: pointer;" :color="obj.unsub || obj.bounced ? 'var(--danger)' : 'var(--success)'">{{ obj.unsub ? 'Unsubscribed' : obj.bounced ? 'Bounced' : 'Subscribed' }}</a-tag>
								<a-tag v-else :color="obj.unsub || obj.bounced ? 'var(--danger)' : 'var(--success)'">{{ obj.unsub ? 'Unsubscribed' : obj.bounced ? 'Bounced' : 'Subscribed' }}</a-tag>
							</a-tooltip>
                        </div>
						<div slot="isAgent" slot-scope="obj" class="text-center">
                            {{obj.isAgent ? 'Yes' : 'No'}}
                        </div>
						<div slot="smsConsent" slot-scope="obj" class="text-center">
                            {{obj.smsConsent ? 'Yes' : 'No'}}
                        </div>
                        <div slot="actions" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                            <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                                <div slot="content">
                                    <div @click="contactDetails(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye mr-2"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg><div>Quick View</div></div>
                                    <div class="popoverContent dF aC" @click="editContact(obj)"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Lead</div>
                                    <div @click="addNote(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square mr-2"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></svg><div>Add Note</div></div>
                                    <div @click="addTask(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square mr-2"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg><div>Add Task</div></div>
                                    <div @click="addAppointment(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar mr-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg><div>Add Appointment</div></div>
                                    <div @click="sendEmail(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail mr-2"><path data-v-1124299c="" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg><div>Email</div></div>
                                    <div @click="convertLead(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign mr-2"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg><div>Add Opportunity</div></div>
                                    <a class="popoverContent dF aC" style="color:rgba(0, 0, 0, 0.65)" :href="`https://transactions.bildhive.${$tld}/new?c=${obj.id}`" target="_blank"><svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exchange-alt" class="mr-2 svg-inline--fa fa-exchange-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg><div></div>Add Transaction</a>
                                    <div @click="deleteContact(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Lead</div></div>
                                </div>
                                <div class="more-option-icon mr-4">
                                    <a-icon style="line-height: 40px;" type="more" />
                                </div>
                            </a-popover>
                        </div>
                    </a-table>
                </div>
                <a-pagination style="padding: 16px 16px; text-align: right;" :defaultCurrent="1" v-model="currentPage" :total="totalContacts"  :showTotal="(total, range) => `${range[0]}-${range[1]} of ${total} Contacts`" :pageSize="pageSize"  />
            </a-card>
        </template>

        <!-- No Contacts Page -->
        <template v-if="!contacts.length && !showExpandDetails">
            <EmptyApp :text="'You don\'t have any contacts'" :img="require('@/assets/emptyIcons/NoLeads.svg')" />
        </template>

        <!-- Expanded Details Contact Page -->
        <!-- <ExpandedDetails @closeexpand="closeExpandDetails"/> -->

    </div>
</template>

<script>
    import ColumnsDrawer from '@/components/contacts/ColumnsDrawer'
    import FilterDrawer from '@/components/contacts/FilterDrawer'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import contactDetails from '@/components/common/contactDetails'
    import moment from 'moment'
    import EditDrawer from '@/components/contacts/EditDrawer'

    export default {
        components:{
            BHLoading,EmptyApp,contactDetails,ColumnsDrawer,FilterDrawer,EditDrawer
        },
        data() {
            return {
                refresh:0,
				modalLoading:false,
                modal:{
                    visible:false,
                    type:''
                },
                assignTo:{
                    owner:[]
                },
                mergeContact:{
                    primary:''
                },
				bulkApplyTags:{
					tags:[]
				},
				bulkDeleteTags:{
					tags:[]
				},
				bulkLeadSource:{
					source:''
				},
				selectAllLeads:false,
                sortItem:{},
                timeNow:Date.now(),
                test:0,
                mailOpen:'',
                filter:'all',
                baseImage:'',
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                selectedRowKeys: [],
                allColumns:[
                    {title:'Name', key:'name', fixed:true},
                    {title:'Email Address', key:'email'},
                    {title:'Tags', key:'tags'},
                    {title:'Sales Owner', key:'owners'},
                    {title:'Company', key:'company'},
                    {title:'Lead Status', key:'status'},
                    {title:'Lead Source', key:'source'},
                    {slots: { title: 'interactionActivity' }, key:'leadScore'},
                    {title:'Last Updated Date', key:'lastUpdateDate'},
                    {title:'Inactive Days', key:'inactiveDays'},
                    {title:'Job Title', key:'jobTitle'},
                    {title:'Phone', key:'phone'},
                    {title:'Street', key:'address'},
                    {title:'City', key:'city'},
                    {title:'Province / State', key:'region'},
                    {title:'Country', key:'country'},
                    {title:'Postal / Zip', key:'postal'},
					{title:'Opt-in Status', key:'unsub'},
                    {title:'Created At', key:'createdAt'},
					{title:'Realtor?', key:'isAgent'},
					{title:'SMS Consent?', key:'smsConsent'},
                ],
                userSource:[]
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : "DD/MM/YYYY"
			},
            columns() {
				if (this.$store.state.contacts.allSettings.userApp.options.columns) {
					let columns = this.$store.state.contacts.allSettings.userApp.options.columns

					let leadScoreIndex = columns.findIndex(c => c.key === 'leadScore');
					if (leadScoreIndex > -1) {
						columns[leadScoreIndex] = { slots: { title: 'interactionActivity' }, key: 'leadScore', sorter: true, scopedSlots: { customRender: 'leadScore' } }
					}

					return columns
				} else return [
					{ title: 'Name', key: 'name', sorter: true, scopedSlots: { customRender: 'name' } },
					{ title: 'Email Address', sorter: true, key: 'email', scopedSlots: { customRender: 'email' } },
					{ title: 'Tags', key: 'tags', scopedSlots: { customRender: 'tags' } },
					{ title: 'Sales Owner', key: 'owners', scopedSlots: { customRender: 'owners' } },
					{ title: 'Lead Status', key: 'status', sorter: true, scopedSlots: { customRender: 'status' } },
					{ slots: { title: 'interactionActivity' }, key: 'leadScore', sorter: true, scopedSlots: { customRender: 'leadScore' } },
					{ title: 'Lead Source', key: 'source', sorter: true, scopedSlots: { customRender: 'source' } },
					{ title: 'Last Updated Date', key: 'lastUpdateDate', sorter: true, scopedSlots: { customRender: 'lastUpdateDate' } },
					{ title: 'Inactive Days', key: 'inactiveDays', sorter: true, scopedSlots: { customRender: 'inactiveDays' } },
					{ title: '', key: 'actions', scopedSlots: { customRender: 'actions' } },
				]
			},
            statuses() {
                return this.$store.state.contacts.statuses || {}
            },
            user() {
                return this.$store.state.user.user
            },
            users() {
                return this.$store.state.contacts.users || []
            },
            storeUsers() {
                let users = []
                this.users.forEach(user => {
                    if (!user || !user.id) return
                    users[user.id] = user
                })
                return users
            },
            totalContacts(){
                return this.$store.state.contacts.totalContacts
            },
            showExpandDetails(){
                return this.$store.state.contacts.expandDetails.visible
            },
            expandDetails(){
                return this.$store.state.contacts.expandDetails
            },
            rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: true,
					selections: [
						{
							key: 'all-data',
							text: 'Select All Leads',
							onSelect: () => {
								this.selectedRowKeys = []
								this.contacts.forEach(x => {
									this.selectedRowKeys.push(x.id)
								})
								this.selectAllLeads = true
							},
						}
					],
                    onSelection: this.onSelection,
                };
            },
            contacts(){
                let time = this.timeNow
                let sort = this.sortItem
                let contacts = {}
                if (!this.$store.state.contacts.filterDrawer.showFilter) {
                    contacts = Object.values(this.$store.state.contacts.allContacts)
                } else {
                    contacts = Object.values(this.$store.state.contacts.filteredContacts)
                }
                contacts = contacts.filter(x => {
                    if (x.visibility != 'everyone' && x.visibility != null){
                        let foundUser = false
                        x.owners.forEach(y => {
                            if (y == this.user.id) foundUser = true
                        })
                        return foundUser || this.$p == 50
                    } else {
                        return true
                    }
                })
                if (sort.key && sort.order && sort.key != '') {
                    if (sort.key == 'name') {
                        if (sort.order == "ascend") {
                            contacts = contacts.sort((a,b) => {
                                if (a.firstName && b.firstName && a.lastName && b.lastName) {
                                    a.name = `${a.firstName} ${a.lastName}`
                                    b.name = `${b.firstName} ${b.lastName}`
                                    if (a.name > b.name) return 1
                                    if (a.name < b.name) return -1
                                    else return 0
                                }
                            })
                        } else if (sort.order == 'descend') {
                            contacts = contacts.sort((a,b) => {
                                if (a.firstName && b.firstName && a.lastName && b.lastName) {
                                    a.name = `${a.firstName} ${a.lastName}`
                                    b.name = `${b.firstName} ${b.lastName}`
                                    if (a.name > b.name) return -1
                                    if (a.name < b.name) return 1
                                    else return 0
                                }
                            })
                        }
                    } else if (sort.key == "lastUpdateDate") {
                        contacts = contacts.sort((a,b) => {
                            if (a.updatedAt && b.updatedAt) {
                                a.update = parseInt(this.moment(a.updatedAt).format('X'))*1000
                                b.update = parseInt(this.moment(b.updatedAt).format('X'))*1000
                                if (sort.order == 'ascend') return a.update - b.update
                                else if (sort.order == 'descend') return b.update - a.update
                            }
                        })
                    } else {
                        if (sort.order == 'ascend') {
                            contacts = contacts.sort((a,b) => {
                                if (a[sort.key] > b[sort.key]) return 1
                                if (a[sort.key] < b[sort.key]) return -1
                                else return 0
                            })
                        } else if (sort.order == 'descend') {
                            contacts = contacts.sort((a,b) => {
                                if (a[sort.key] > b[sort.key]) return -1
                                if (a[sort.key] < b[sort.key]) return 1
                                else return 0
                            })
                        }
                    }
                }
                return contacts
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            searchTimeStamp(){
                return this.$store.state.contacts.searchTimeStamp
            },
            currentPage:{
                get(){
                    return this.$store.state.contacts.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            tags(){
                return this.$store.state.contacts.tags
            },
            searchOBJ(){
                return this.$store.state.contacts.searchOBJ
            },
            resultLength(){
                return this.$store.state.contacts.resultLength
            },
            tasks(){
                return this.$store.state.contacts.tasks
            },
            opportunities() {
                return this.$store.state.contacts.opportunities
            },
            appointments(){
                return this.$store.state.contacts.appointments
            },
            showingContactMode(){
                return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) `: `Found ${this.resultLength} Contacts `
            },
            newStoreTime(){
                return this.$store.state.contacts.newStoreTime
            },
            instance(){
                return this.$store.state.instance
            },
            pageSize(){
                return this.$store.state.contacts.pageSize
            },
            query(){
                return this.$store.getters.query
            },
            loadingPage:{
                get(){
                    return this.$store.state.contacts.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            },
            storeContacts() {
                return this.$store.state.contacts.allContacts
            },
			leadSources() {
				if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource?.length) {
					return this.$store.state.contacts.allSettings.app.options.leadSource
				} else {
					return [
						{name:'No Source', id:'nosource'},
						{name:'Email', id:'email'},
						{name:'Cold Call', id:'coldcall'},
						{name:'Advertising', id:'advertising'}
					]
				}
			},
			queryParams(){
				return this.$store.state.contacts.queryParams
			},
			filterQuery() {
				return this.$store.state.contacts.filterDrawer.query
			},
			sortQuery() {
				return this.$store.state.contacts.sortQuery
			},
			nylasAccount() {
				return this.$store.state.contacts.nylasAccount || {}
			}
        },
        methods:{
			viewBounceReason(contact){
				this.loading = true;
				this.$api.get(`/contacts/:instance/${contact.id}/bouncereason`).then(({ data }) => {
					this.$message.info(data);
					this.loading = false;
				}).catch(err => {
					this.$message.error(this.$err(err))
				}).finally(() => {
					this.loading = false;
				})
			},
			selectAll(){
				this.selectedRowKeys = []
				this.selectAllLeads = true
			},
            getInactive(updatedAt) {
                let today = moment(new Date())
                let update = moment(updatedAt)
                return Math.round(today.diff(update, 'days', true))
            },
            unique(array) {
                if (typeof array[0] == 'object') {
                    let ids = array.map(x => x = x.id)
                    array = [...new Set(ids)]
                } else if (typeof array[0] == 'string') {
                    array = [...new Set(array)]
                }
                return array
            },
            bulkEmail() {
				if (this.nylasAccount && this.nylasAccount.email){
					this.$store.commit('BULK_EMAIL', this.selectedRowKeys)
					this.$store.commit('ADD_NEW', 'Email')
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            },
            bulkDelete() {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Leads')
                }
                let self = this
                this.$confirm({
                    title: 'Delete Leads',
                    content: h => <div>Do you want to delete the selected leads? All information related to them will be lost.</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered: true,
                    onOk(){
                        self.$api.put(`/contacts/:instance/bulk`, self.selectedRowKeys).then(({data}) => {
                            if(data.success) {
                                self.$store.commit('SET_TOTAL_RESULT', self.totalContacts - self.selectedRowKeys.length)
                                if (self.contacts.length == 1 && self.currentPage != 1){
                                    self.currentPage = self.currentPage - 1
                                    self.$store.dispatch('DO_SEARCH')
                                } else {
                                    self.$store.dispatch('DO_SEARCH')
                                }
                                self.selectedRowKeys = []
                            }
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel(){
                        console.log('CANCELED')
                    }
                })
            },
            update() {
                this.refresh = Date.now()
            },
            req:msg=>({required:true,message:msg}),
            searchUser(input){
                if(input && input.length>1) {
                    this.users.forEach(x => {
                        x.name = x.firstName+ ' ' + x.lastName
                    })
                    this.userSource = this.users.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                } else {
                    return this.userSource = []
                }
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
			tagsToIds(obj){

				let tags = obj.tags || []

				obj.newTags = []
				let oldTags = []

				let self = this

				tags.forEach(tID => {
				if (Object.values(self.tags).find(x => x.name === tID)) {
					let find = Object.values(self.tags).find(x => x.name == tID)
					oldTags.push(find.id)
				}
				else obj.newTags.push(tID)
				})
				obj.tags = oldTags

				return obj

			},
            submit() {
                if (this.modal.type == 'assignTo') {
                    this.$refs.assignTo.validate(valid => {
                        if (valid) {
							this.modalLoading = true
                            this.$api.put(`/contacts/:instance/assign`, {contacts:this.selectedRowKeys, owners:this.assignTo.owner}).then(({data}) => {
                                if (data.success) {
                                    this.selectedRowKeys.forEach(id => {
                                        if (this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[id]) {
                                            let data = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[id]))
                                            data.owners = this.assignTo.owner
                                            this.$store.commit('SET_PROP', {where:['allContacts',data.id],what:data} )
                                        }
                                    })
									this.modalLoading = false
                                    this.closeModal()
                                }
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    })
                } else if (this.modal.type == 'merge') {
                    this.$refs.mergeContact.validate(valid => {
                        if (valid) {
                            console.log('merge', this.mergeContact.primary)
                        }
                    })
                } else if (this.modal.type == 'bulkApplyTags'){
					this.$refs.bulkApplyTags.validate(valid => {
                        if (valid) {
							this.modalLoading = true
							let sendObj = JSON.parse(JSON.stringify(this.bulkApplyTags))
							sendObj = this.tagsToIds(sendObj)
							sendObj.selected = this.selectedRowKeys
							sendObj.all = this.selectAllLeads
							let queryParams = ''
							if (this.selectAllLeads){
								queryParams = '?' + this.queryParams
							}
                            this.$api.post(`/contacts/:instance/bulk-apply-tags${queryParams}`, sendObj).then(({data}) => {
								if (this.selectAllLeads){
									this.contacts.forEach(contact => {
										data.tags.forEach(x => {
											let find = contact.tags.find(y => {
												if (typeof y === 'object'){
													return y.id == x.id
												} else {
													return y == x.id
												}
											})
											if (!find) contact.tags.push(x)
										})
										this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
									})
								} else {
									this.selectedRowKeys.forEach(id => {
										if (this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[id]) {
											let contact = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[id]))
											data.tags.forEach(x => {
												let find = contact.tags.find(y => {
													if (typeof y === 'object'){
														return y.id == x.id
													} else {
														return y == x.id
													}
												})
												if (!find) contact.tags.push(x)
											})
											this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
										}
									})
								}
								this.modalLoading = false
								this.closeModal()
                            }).catch(err => {
								this.modalLoading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    })
				} else if (this.modal.type == 'bulkDeleteTags'){
					this.$refs.bulkDeleteTags.validate(valid => {
                        if (valid) {
							this.modalLoading = true
							let sendObj = JSON.parse(JSON.stringify(this.bulkDeleteTags))
							sendObj = this.tagsToIds(sendObj)
							sendObj.selected = this.selectedRowKeys
							sendObj.all = this.selectAllLeads
							let queryParams = ''
							if (this.selectAllLeads){
								queryParams = '?' + this.queryParams
							}
                            this.$api.post(`/contacts/:instance/bulk-remove-tags${queryParams}`, sendObj).then(({data}) => {
								if (this.selectAllLeads){
									this.contacts.forEach(contact => {
										sendObj.tags.forEach(x => {
											let findIndex = contact.tags.findIndex(y => {
												if (typeof y === 'object'){
													return y.id == x
												} else {
													return y == x
												}
											})
											if (findIndex != -1) contact.tags.splice(findIndex, 1)
										})
										this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
									})
								} else {
									this.selectedRowKeys.forEach(id => {
										if (this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[id]) {
											let contact = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[id]))
											sendObj.tags.forEach(x => {
												let findIndex = contact.tags.findIndex(y => {
													if (typeof y === 'object'){
														return y.id == x
													} else {
														return y == x
													}
												})
												if (findIndex != -1){
													contact.tags.splice(findIndex, 1)
												}
											})
											this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
										}
									})
								}
								this.modalLoading = false
								this.closeModal()
                            })
                        }
                    })
				} else if (this.modal.type == 'bulkLeadSource'){
					this.$refs.bulkLeadSource.validate(valid => {
                        if (valid) {
							// this.modalLoading = true
							let sendObj = JSON.parse(JSON.stringify(this.bulkLeadSource))
							sendObj.selected = this.selectedRowKeys
							sendObj.all = this.selectAllLeads
							if (sendObj.source == 'nosource') sendObj.clear = true;
							let queryParams = ''
							if (this.selectAllLeads){
								queryParams = '?' + this.queryParams
							}
                            this.$api.post(`/contacts/:instance/bulk-apply-source${queryParams}`, sendObj).then(({data}) => {
								if (this.selectAllLeads){
									this.contacts.forEach(contact => {
										if (sendObj.clear) contact.source = ''
										else contact.source = sendObj.source
										this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
									})
								} else {
									this.selectedRowKeys.forEach(id => {
										if (this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[id]) {
											let contact = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[id]))
											if (sendObj.clear) contact.source = ''
											else contact.source = sendObj.source
											this.$store.commit('SET_PROP', {where:['allContacts',contact.id],what:contact} )
										}
									})
								}
								this.modalLoading = false
								this.closeModal()
                            }).catch(err => {
								this.modalLoading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    })
				}
            },
            openModal(type) {
                this.modal.visible = true
                if (type == 'assignTo') {
                    this.assignTo.owner = [this.user.id]
                    this.modal.type = 'assignTo'
                    this.userSource.push(this.user)
                } else {
					this.modal.type = type
				}
            },
            closeModal() {
                if (this.modal.type == 'assignTo') {
                    if(this.$refs.assignTo) this.$refs.assignTo.resetFields()
                    this.assignTo.owner = []
                } else if (this.modal.type == 'merge') {
                    if(this.$refs.mergeContact) this.$refs.mergeContact.resetFields()
                    this.mergeContact.primary = ''
                } else if (this.modal.type == 'bulkApplyTags') {
					if(this.$refs.bulkApplyTags) this.$refs.bulkApplyTags.resetFields()
					this.bulkApplyTags.tags = []
				} else if (this.modal.type == 'bulkDeleteTags') {
					if(this.$refs.bulkDeleteTags) this.$refs.bulkDeleteTags.resetFields()
					this.bulkDeleteTags.tags = []
				} else if (this.modal.type == 'bulkLeadSource') {
					if(this.$refs.bulkLeadSource) this.$refs.bulkLeadSource.resetFields()
					this.bulkLeadSource.source = ''
				}
                this.modal.visible = false
                this.modal.type = ''
				this.selectAllLeads = false
                this.userSource = []
                this.selectedRowKeys = []
            },
            convertLead(contact) {
                if (contact.id && contact.id != '') {
                    this.$store.commit('ADD_NEW', 'Lead Opportunity')
                    this.$store.commit('ADD_LEAD_ACTION', contact.id)
                }
            },
            showFilter() {
                this.$store.commit('OPEN_FILTER_DRAWER')
            },
            displayPhone(num){
				if (num && num.length){
					let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
					let first = phone.substring(0,3)
					let middle = phone.substring(3,6)
					let last = phone.substring(6,10)

					return '('+first+') '+middle+' '+last
				} else {
					return ''
				}

            },
            moment,
            handleChange(pagination, filter, sorter) {
                if (!sorter.order) this.sortItem = {}
                else if(sorter.columnKey) {
                    this.sortItem = {
                        key:sorter.columnKey,
                        order:sorter.order
                    }
                }

                if (this.sortItem && Object.keys(this.sortItem).length != 0) {

                    let order = ''

                    let query = ''

                    if (this.sortItem.order == 'descend') order = 'DESC'
                    if (this.sortItem.order == 'ascend') order = 'ASC'

                    if (this.sortItem.key == 'name') {
                        query = '&_sort=firstName:' + order
                    }
                    else if (this.sortItem.key == 'lastUpdateDate') query = '&_sort=updatedAt:' + order
                    else if (this.sortItem.key == 'inactiveDays') {
                        if (order == 'DESC') order = 'ASC'
                        else order = 'DESC'
                        query = '&_sort=updatedAt:' + order
                    }
                    else if (this.sortItem.key == 'status') query = '&_sort=leadStatus:' + order
                    else {
                        query = '&_sort=' + this.sortItem.key + ':' + order
                    }
                    if (query && query.length != 0) query = query.substring(1)
                    this.$store.commit('EDIT_SORT_QUERY', query)
                } else this.$store.commit('EDIT_SORT_QUERY', '')
                this.$store.dispatch('DO_SEARCH')
            },
            updateColumns(keys) {
                let columns = []
                keys.forEach(key => {
                    let index = this.columns.findIndex(x => x.key == key)
                    if (index != -1) {
                        columns.push(this.columns[index])
                    } else {
                        let allIndex = this.allColumns.findIndex(x => x.key == key)
                        if (allIndex != -1) {
                            if (key != 'phone' && key != 'owners') {
								columns.push({ title: key === 'leadScore' ? 'Interaction Activity' : this.allColumns[allIndex].title, key: this.allColumns[allIndex].key, sorter: true, scopedSlots: { customRender: this.allColumns[allIndex].key } })
							} else {
								columns.push({ title: this.allColumns[allIndex].title, key: this.allColumns[allIndex].key, scopedSlots: { customRender: this.allColumns[allIndex].key } })
							}
                        }
                    }
                })
                let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.userApp))
                settings.options.columns = columns
                this.$api.post('/settings/:instance/contacts/user', settings).then(({data}) => {
                    this.$store.commit('UPDATE_USER_ALLSETTINGS',data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                // this.columns = columns
            },
            showColumns() {
                this.$store.commit('OPEN_COLUMNS_DRAWER', {})
            },
            editContact(obj){
                this.$router.push(`/leads/${obj.id}`)
            },
            onClose({type='',data={}}){
                this.$store.dispatch('CLOSE_CONTACT_DRAWER',{type,data})
            },
            closeExpandDetails(obj){

                obj.tags.forEach( x => {
                    if (!this.tags.hasOwnProperty(x.id)){
                        this.$store.commit('UPDATE_TAGS',x)
                    }
                })
                this.$store.commit('CLOSE_EXPAND_DETAILS')
                this.$store.commit('SET_PROP', {where:['allContacts',obj.id],what:obj})
                this.timeNow = Date.now()
            },
            convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
            addNote(obj){
                if(obj.id && obj.id != '') {
                    this.$store.commit('ADD_NEW', 'Note')
                    this.$store.commit('ADD_LEAD_ACTION', obj.id)
                }
            },
            addTask(data){
                if(data.id && data.id != '') {
                    this.$store.commit('ADD_NEW', 'Task')
                    this.$store.commit('ADD_LEAD_ACTION', data.id)
                }
            },
            addAppointment(obj){
                if(obj.id && obj.id != '') {
                    this.$store.commit('ADD_NEW', 'Appointment')
                    this.$store.commit('ADD_LEAD_ACTION', obj.id)
                }
            },
            sendEmail(obj){
                if (obj.id && obj.id != '') {
					if (this.nylasAccount && this.nylasAccount.email){
						this.$store.commit('ADD_LEAD_ACTION', obj.id)
						this.$store.commit('ADD_NEW', 'Email')
					} else {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
							duration: 5
						});
					}
                }
            },
            deleteContact(data){
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Lead')
                }
                let self = this
                this.$confirm({
                    title: 'Delete Lead',
                    content: h => <div>Do you want to delete <b>{data.email}</b>? All information related to them will be lost.</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered: true,
                    onOk(){
                        self.tasks.forEach( task => {
                            if (task.relatedType == 'contact' && (task.contact && task.contact.id) == data.id){
                                self.$store.commit('DELETE_TASK', task)
                            }
                        })
                        self.appointments.forEach( appointment => {
                            if (appointment.relatedType == 'contact' && appointment.contact && data && appointment.contact.id == data.id){
                                self.$store.commit('DELETE_APPOINTMENT', appointment)
                            }
                        })
                        self.opportunities.forEach( opp => {
                            if (opp.contact && opp.contact.id == data.id){
                                self.$store.commit('DELETE_OPPORTUNITY', opp)
                            }
                        })
                        self.$api.delete(`/contacts/${self.instance.id}/${data.id}`).then( ({data}) => {
                            self.$store.commit('SET_TOTAL_RESULT', self.totalContacts - 1)
                            if (self.contacts.length == 1 && self.currentPage != 1){
                                self.currentPage = self.currentPage - 1
                                self.$store.dispatch('DO_SEARCH')
                            } else {
                                self.$store.dispatch('DO_SEARCH')
                            }
                            self.onClose({type:'delete',data})
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel(){
                        console.log('CANCELED')
                    }
                })
            },
            contactDetails(obj){
                this.$store.commit('OPEN_CONTACT_DETAILS',obj)

            },
            onSelectChange(selectedRowKeys) {
				this.selectAllLeads = false
                this.selectedRowKeys = selectedRowKeys;
            },
            removeSearch({srch,searchKey}){
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
			getLeadSourceName(source){
				const find = this.leadSources.find(ls => ls.id === source);
				return find ? find.name : isNaN(source) ? source : 'No Source'
			},
			allTags(tagIds){
				const names = [];
				tagIds.forEach(id => {
					if(this.tags[id]){
						names.push(this.tags[id].name)
					}
				})
				return names.join(',  ');
			},

			downloadFile() {
				if (this.$p < 40) {
					return this.$message.error(
						"You do not have permission for the export feature"
					);
				}

				this.$store.commit("LOAD", true);
				let suffix = "dev";
				if (
					location.host.includes("bildhive.dev") ||
					window.location.host.includes("localhost")
				) {
					suffix = "dev";
				} else {
					suffix = "com";
				}

				let query = ''
				if (this.filterQuery.trim().length != 0 || this.sortQuery.trim().length != 0) {
					let filterQuery = this.filterQuery
					let sortQuery = this.sortQuery
					query = sortQuery.trim().length != 0 && filterQuery.trim().length != 0 ? sortQuery + '&' + filterQuery : sortQuery + filterQuery
				}

				fetch(
					`https://hook.bildhive.${suffix}/contacts/${this.instance.id}/export?${query}`,
					{
						headers: {
							"Content-Type": "text/csv",
							Authorization: `Bearer ${this.getCookie("__bhjt")}`,
							"x-subdomain": "contacts"
						},
						responseType: "blob",
					}
				)
					.then((response) => {
						return response.blob();
					})
					.then((blob) => {
						var a = window.document.createElement("a");
						a.href = window.URL.createObjectURL(blob, {
							type: "text/csv",
						});
						a.download = `${this.instance.name}-${Date.now()}.csv`;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						return this.$notification['success']({
							message: 'Export Complete',
							description: `Your contacts have been exported successfully.\n${new Date()}`,
							duration: 4
						});
					})
					.catch((err) => {
						console.error("err", err);
						this.$message.error(
							"An error occurred please try again"
						);
					})
					.finally(() => {
						this.$store.commit("LOAD", false);
					});
			},

			getCookie(cname) {
				const name = cname + "=";
				const decodedCookie = decodeURIComponent(document.cookie);
				const ca = decodedCookie.split(";");
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					while (c.charAt(0) == " ") {
						c = c.substring(1);
					}
					if (c.indexOf(name) == 0) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},
        },
        created() {
			if (this.$route.query && this.$route.query.status) {
				this.$store.commit('EDIT_QUERY', `${this.$route.query.status}=true`)
			}
			// else {
			// 	this.$store.commit('EDIT_QUERY', '')
			// }
			if (this.$route.query && this.$route.query._sort) {
				this.$store.commit('EDIT_SORT_QUERY', `_sort=${this.$route.query._sort}`)
			}
			// else {
			// 	this.$store.commit('EDIT_SORT_QUERY', '')
			// }
            this.$store.commit('SIDEBAR_SELECTION',{value: 'Leads'})
            this.$store.dispatch('DO_SEARCH')
            // this.$store.dispatch('DO_TAGS')
            // this.$store.dispatch('DO_SOURCE')
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .leadButtons{
        @media screen and(min-width:1030px){
            display:flex;
        }
        @media screen and(max-width:1030px){
            margin-bottom:10px;
        }
    }
    .bulk-action-button:hover{
        color:var(--orange)
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }


</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .card-headers .ant-card-body {
        padding: 15px 25px;
    }
	.lead-scoring .ant-rate-star:not(:last-child) {
		margin-right: 0px;
	}
</style>
