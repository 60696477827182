<template>
    <div>
        <a-drawer
            :zIndex="1000"
            :width="500"
            @close="onClose"
            :visible="filterDrawer.visible"
            :wrapStyle="{overflow: 'auto',}"
            >
            <bhLoading :show="loading" />
            <div class="h-full dF fC jSB" style="margin-bottom: 60px;">
                <div class="f1">
                    <h5>Filters</h5>
                    <div v-if="query != ''" style="display:inline-block">
                        <div @click="clearFilter" class="dF aC mt-3 py-2 px-4" style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer"><div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" /></div>
                    </div>
                    <div class="mt-5">
                    <a-collapse expandIconPosition="right" class="collapse-display">
                        <template #expandIcon="props">
                            <a-icon type="down" :rotate="props.isActive ? -180 : 0" />
                        </template>
                        <a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none; width:400px">Last Updated Date</div>
                            <a-checkbox v-if="!dateRange" v-model="filter.hideInactive">Hide contacts that have been inactive for 6 months or more</a-checkbox>
                            <a-range-picker v-if="!filter.hideInactive" class="w-full range-display" v-model="dateRange" :ranges="{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }" @change="onChange">
                                <div class="w-full dF aC jSB">
                                    <div class="mr-5" v-if="dateRange">{{displayRange}}</div>
                                    <div style="color:var(--orange); cursor:pointer;">{{!dateRange ? 'Select Date Range':'Update Date Range'}}</div>
                                </div>
                            </a-range-picker>
                        </a-collapse-panel>
						<a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none; width:400px">Created At Date</div>
                            <a-range-picker class="w-full range-display" v-model="createdDateRange" :ranges="{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }" @change="onChange2">
                                <div class="w-full dF aC jSB">
                                    <div class="mr-5" v-if="createdDateRange">{{displayCreatedRange}}</div>
                                    <div style="color:var(--orange); cursor:pointer;">{{!createdDateRange ? 'Select Date Range':'Update Date Range'}}</div>
                                </div>
                            </a-range-picker>
                        </a-collapse-panel>
                        <a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Owned By</div>
                            <template v-if="filter.owners">
                                <a-checkbox class="mb-2" @change="selectOption('owners','any')" :checked="filter.owners.includes('any')">Any</a-checkbox>
								<template v-for="(owner, ownerI) in owners">
									<div :class="ownerI != 0 ? 'mt-2':''" v-if="owner && owner.id" :key="owner.id+ownerI" :value="owner.id+ownerI">
										<a-checkbox @change="selectOption('owners',owner.id)" :checked="filter.owners.includes(owner.id)">{{`${owner.firstName} ${owner.lastName}`}}</a-checkbox>
									</div>
								</template>
                            </template>
                        </a-collapse-panel>
                        <a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Lead Status</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.leadStatus && filter.leadStatus.length && filter.leadStatus.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('leadStatus','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8" v-for="status in Object.keys(leadStatus)" :key="status" :value="status">
                                    <div :style="filter.leadStatus && filter.leadStatus.length && filter.leadStatus.includes(status) ? 'background-color: #CAC3D0;':''" @click="selectOption('leadStatus',status)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{leadStatus[status].name}}</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
						<a-collapse-panel v-if="leadSources.length && leadSources.length > 0">
                            <div class="py-2" slot="header" style="border:none">Lead Source</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.source && filter.source.length && filter.source.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('source','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
								<template v-for="(index, indexI) in groupedSources">
									<a-col v-if="indexI != '-'" :span="24" class="mt-2">
										<span style="font-weight: bold;">{{ indexI }}</span>
									</a-col>
									<a-col :span="8" v-for="(source) in index" :key="source.id" :value="source.id">
										<div :style="filter.source && filter.source.length && filter.source.includes(source.id) ? 'background-color: #CAC3D0;':''" @click="selectOption('source',source.id)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{source.name}}</div>
									</a-col>
								</template>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Tags</div>
                            <a-select
                                v-if="filter.tags"
                                v-model="filter.tags"
                                mode="multiple"
                                placeholder="Type to search..."
                                size="large"
								style="width:100%"
                                :filter-option="filterTag"
								>
                                <a-select-option v-for="tag in tagSource" :key="tag.id" :value="tag.id">
                                    {{ tag.name }}
                                </a-select-option>
                            </a-select>
                        </a-collapse-panel>
                        <a-collapse-panel v-if="cities.length && cities.length > 0">
                            <div class="py-2" slot="header" style="border:none">City</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.city && filter.city.length && filter.city.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('city','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8" v-for="(city, cityI) in cities" :key="city+cityI" :value="city+cityI">
                                    <div :style="filter.city && filter.city.length && filter.city.includes(city) ? 'background-color: #CAC3D0;':''" @click="selectOption('city',city)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{city}}</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel v-if="regions.length && regions.length > 0">
                            <div class="py-2" slot="header" style="border:none">State / Province</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.region && filter.region.length && filter.region.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('region','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8" v-for="(region, regionI) in regions" :key="region+regionI" :value="region+regionI">
                                    <div :style="filter.region && filter.region.length && filter.region.includes(region) ? 'background-color: #CAC3D0;':''" @click="selectOption('region',region)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{region}}</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel v-if="countries.length && countries.length > 0">
                            <div class="py-2" slot="header" style="border:none">Country</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.country && filter.country.length && filter.country.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('country','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8" v-for="(country, countryI) in countries" :key="country+countryI" :value="country+countryI">
                                    <div :style="filter.country && filter.country.length && filter.country.includes(country) ? 'background-color: #CAC3D0;':''" @click="selectOption('country',country)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">{{country}}</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Postal / Zip</div>
                            <a-input v-model="filter.postal" placeholder="Postal / Zip" size="large" />
                        </a-collapse-panel>
						<a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Opt-in Status</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="6">
                                    <div :style="filter.unsub && filter.unsub.length && filter.unsub.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('unsub','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
								<a-col :span="6">
                                    <div :style="filter.unsub && filter.unsub.length && filter.unsub.includes(false) ? 'background-color: #CAC3D0;':''" @click="selectOption('unsub',false)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Subscribed</div>
                                </a-col>
								<a-col :span="6">
                                    <div :style="filter.unsub && filter.unsub.length && filter.unsub.includes(true) ? 'background-color: #CAC3D0;':''" @click="selectOption('unsub',true)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Unsubscribed</div>
                                </a-col>
								<a-col :span="6">
                                    <div :style="filter.unsub && filter.unsub.length && filter.unsub.includes('bounced') ? 'background-color: #CAC3D0;':''" @click="selectOption('unsub','bounced')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Bounced</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
						<a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">Realtor?</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.isAgent && filter.isAgent.length && filter.isAgent.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('isAgent','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8">
                                    <div :style="filter.isAgent && filter.isAgent.length && filter.isAgent.includes(true) ? 'background-color: #CAC3D0;':''" @click="selectOption('isAgent',true)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Yes</div>
                                </a-col>
								<a-col :span="8">
                                    <div :style="filter.isAgent && filter.isAgent.length && filter.isAgent.includes(false) ? 'background-color: #CAC3D0;':''" @click="selectOption('isAgent',false)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">No</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
						<a-collapse-panel>
                            <div class="py-2" slot="header" style="border:none">SMS Consent?</div>
                            <a-row :gutter="[16, 16]">
                                <a-col :span="8">
                                    <div :style="filter.smsConsent && filter.smsConsent.length && filter.smsConsent.includes('any') ? 'background-color: #CAC3D0;':''" @click="selectOption('smsConsent','any')" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Any</div>
                                </a-col>
                                <a-col :span="8">
                                    <div :style="filter.isAgent && filter.smsConsent.length && filter.smsConsent.includes(true) ? 'background-color: #CAC3D0;':''" @click="selectOption('smsConsent',true)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">Yes</div>
                                </a-col>
								<a-col :span="8">
                                    <div :style="filter.isAgent && filter.smsConsent.length && filter.smsConsent.includes(false) ? 'background-color: #CAC3D0;':''" @click="selectOption('smsConsent',false)" style="border:1px solid #D0C9D6; border-radius:6px; width:100%; cursor:pointer" class="py-1 px-3 text-center filter-options">No</div>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                    </a-collapse>
                    </div>
                </div>
                <div style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
                    <div class="dF jE">
                        <div class="dF ml-3">
                            <a-button size="large" class="mr-3 cancel-button" @click="onClose">CANCEL</a-button>
                            <a-button size="large" @click="submit" type="primary">APPLY FILTER</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import moment from 'moment'
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{bhLoading},
    data() {
        return {
            tagSource:[],
            dateRange:undefined,
			createdDateRange:undefined,
            loading:false,
            displayRange:'',
			displayCreatedRange:'',
            filter:{},
        }
    },
    watch: {
        visible:{
            handler(val) {
                if (val) {
					this.tagSource = this.tags;
                    this.filter = JSON.parse(JSON.stringify(this.filterDrawer.filter))
                    if (this.filter.lastUpdateDate && this.filter.lastUpdateDate.length) {
						this.dateRange = [];
                        this.dateRange[0] = moment(this.filter.lastUpdateDate[0])
                        this.dateRange[1] = moment(this.filter.lastUpdateDate[1])
                    }
					if (this.filter.createdDate && this.filter.createdDate.length) {
						this.createdDateRange = [];
                        this.createdDateRange[0] = moment(this.filter.createdDate[0])
                        this.createdDateRange[1] = moment(this.filter.createdDate[1])
                    }
                }
            }
        }
    },
    computed:{
        sortQuery() {
            return this.$store.state.contacts.sortQuery
        },
        query() {
            return this.$store.state.contacts.filterDrawer.query
        },
        contacts() {
            return this.$store.state.contacts.allContacts || {}
        },
        countries() {
            let contacts = this.$store.state.contacts.allContacts || {}
            let countries = []
            Object.keys(contacts).forEach(key => {
                if (contacts[key].country && contacts[key].country != '' && contacts[key].country != 'Select a country') {
                    if (!countries.includes(contacts[key].country)) countries.push(contacts[key].country)
                }
            })
            return countries
        },
        regions() {
            let contacts = this.$store.state.contacts.allContacts || {}
            let regions = []
            Object.keys(contacts).forEach(key => {
                if (contacts[key].region && contacts[key].region != '') {
                    if (!regions.includes(contacts[key].region)) regions.push(contacts[key].region)
                }
            })
            return regions
        },
        cities() {
            let contacts = this.$store.state.contacts.allContacts || {}
            let cities = []
            Object.keys(contacts).forEach(key => {
                if (contacts[key].city && contacts[key].city != '') {
                    if (!cities.includes(contacts[key].city)) cities.push(contacts[key].city)
                }
            })
            return cities
        },
        tags(){
            return Object.values(this.$store.state.contacts.tags)
        },
        visible() {
            return this.$store.state.contacts.filterDrawer.visible
        },
        opportunities() {
            return this.$store.state.contacts.opportunities
        },
        owners() {
            return this.$store.state.contacts.users || []
        },
        filterDrawer() {
            return this.$store.state.contacts.filterDrawer
        },
        leadStatus() {
            return this.$store.state.contacts.statuses || {}
        },
		forms(){
			return this.$store.state.contacts.forms
		},
		groupedSources(){
			let origForms = Object.values(this.forms)

			let newSources = {}
			this.leadSources.forEach(source => {
				let find = origForms.find(x => x.name == source.name)
				if (find){
					if (find.group && find.group.name){
						newSources[find.group.name] = [...newSources[find.group.name] || [], source]
					} else {
						newSources['-'] = [...newSources['-'] || [], source]
					}
				} else {
					newSources['-'] = [...newSources['-'] || [], source]
				}
			})

			let temp = newSources['-']
			delete newSources['-']
			newSources = {'-':temp, ...newSources}

			return newSources
		},
		leadSources() {
			if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource?.length) {
                return this.$store.state.contacts.allSettings.app.options.leadSource
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
		}
    },
    methods:{
        filterTag(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        onChange(dates, dateStrings) {
            this.displayRange = `${dateStrings[0]} - ${dateStrings[1]}`
            this.filter.lastUpdateDate = [dates[0].format('X')*1000, dates[1].format('X')*1000]
            return [dateStrings[0],dateStrings[1]]
        },
		onChange2(dates, dateStrings) {
            this.displayCreatedRange = `${dateStrings[0]} - ${dateStrings[1]}`
            this.filter.createdDate = [dates[0].format('X')*1000, dates[1].format('X')*1000]
            return [dateStrings[0],dateStrings[1]]
        },
        moment,
        submit() {
            let query = ''
            Object.keys(this.filter).forEach(key => {
                if ((typeof this.filter[key] == 'object' && !this.filter[key].includes('any') && this.filter[key].length != 0) || (typeof this.filter[key] == 'string' && this.filter[key] != '' && this.filter[key].trim()) || (typeof this.filter[key] == 'boolean' && this.filter[key] != false)) {
                    if (typeof this.filter[key] == 'object') {
                        if (key == 'lastUpdateDate') {
                            let start = new Date(this.filter[key][0]).toISOString()
                            let end = new Date(this.filter[key][1]).toISOString()
                            query = query + `&updatedAt_gte=${start}&updatedAt_lte=${end}`
                        } else if (key == 'createdDate') {
							let start = new Date(this.filter[key][0]).toISOString()
                            let end = new Date(this.filter[key][1]).toISOString()
                            query = query + `&createdAt_gte=${start}&createdAt_lte=${end}`
						} else if (key == 'unsub') {
                            this.filter[key].forEach(val => {
								if(val === 'bounced'){
									query = query +`&bounced=true`
								} else {
									query = query +`&${key}=${val}`
								}
                            })
                        } else{
                            this.filter[key].forEach(val => {
                                query = query +`&${key}=${val}`
                            })
                        }
                    } else if (typeof this.filter[key] == 'string') {
                        query = query + `&${key}=${this.filter[key]}`
                    } else if (typeof this.filter[key] == 'boolean') {
                        if (key == 'hideInactive') {
                            let month = new Date().getMonth()
                            let inactive = moment().month(month-6).startOf("month").toDate().toISOString()
                            query = query + `&updatedAt_gte=${inactive}&updatedAt_lte=${new Date().toISOString()}`
                        }
                    }
                }
            })
            if (query && query.length != 0) query = query.substring(1)
            this.$store.commit('EDIT_QUERY',query)
            this.$store.dispatch('DO_SEARCH')
            this.onClose()
        },
        checkIncludes(arr, array) {
            let include = false
            if (array.length && typeof array[0] == 'object') {
                array = array.map(x => x = x.id)
            }
            array.forEach(id => {
                if (arr.includes(id)) include = true
            })
            return include
        },
        selectOption(type, value) {
            if (value != 'any') {
                if(this.filter[type].length == 1 && this.filter[type][0] == 'any') {
                    this.filter[type].splice(0,1)
                }
                if (!this.filter[type].includes(value)) {
                    this.filter[type].push(value)
                } else {
                    let index = this.filter[type].findIndex(x => x == value)
                    this.filter[type].splice(index,1)
                }
            } else {
                this.filter[type] = ['any']
            }
            if (this.filter[type].length == 0) {
                this.filter[type] = ['any']
            }
        },
        clearFilter() {
            let filter = {
                lastUpdateDate: [],
				createdDate: [],
                inactiveDays: [],
                owners: ['any'],
                leadStatus: ['any'],
				source:['any'],
                opportunities: ['any'],
                tags: [],
                city: ['any'],
                region: ['any'],
                country: ['any'],
                postal: '',
				unsub: ['any'],
                hideInactive:false,
				isAgent: ['any'],
				smsConsent: ['any']
            }
            this.dateRange = undefined
			this.createdDateRange = undefined
            this.displayRange = ''
			this.displayCreatedRange = ''
            this.$store.commit('LOAD_PAGE', true)
            this.$store.commit('EDIT_QUERY', '')
            this.$store.dispatch('DO_SEARCH')
            this.$store.commit('CLOSE_FILTER_DRAWER', filter)
        },
        onClose() {
            this.$store.commit('CLOSE_FILTER_DRAWER', this.filter)
        }
    }
}
</script>

<style>
.range-display .ant-tag-blue{
    color:black !important;
}
.collapse-display.ant-collapse{
    border:none !important;
}
.collapse-display.ant-collapse > .ant-collapse-item{
    border-bottom:none !important;
}
.collapse-display .ant-collapse-header{
    padding:none !important;
}
.collapse-display.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding:0 !important;
}
.collapse-display .ant-collapse-content{
    border:none !important;
}
.cancel-button.ant-btn:hover {
    border-color:#ECE9F1 !important;
}
</style>
<style scoped>
.filter-options:hover{
    background-color: #ECE9F1;
}
.hide-display{
    height:0;
    transition:height .3s ease-in-out;
    overflow: hidden;
}
.show-display{
    height:200px;
}</style>
